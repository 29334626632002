<template>
	<v-container fluid class="mt-4 ml-2 pr-6">
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title class="text-subtitle-1">
	  		    Renta de sucursales
	  		    <v-spacer></v-spacer>
	  		    <v-btn color="primary" @click="initialize()" small rounded>Consultar</v-btn>
	  		    <v-btn color="secondary" @click="dialogNuevaNomina.estatus = true" small rounded class="ml-2">Agregar</v-btn>
	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row>
	  		  		<v-col cols="12" md="6" lg="2" xl="2">
	  		  			<v-text-field
	  		  				filled
	  		  				single-line
	  		  			  label="Fecha inicio"
	  		  			  type="date"
	  		  			  hide-details
	  		  			  v-model="fecha_inicio"
	  		  			  dense
	  		  			></v-text-field>
	  		  		</v-col>

	  		  		<v-col cols="12" md="6" lg="2" xl="2">
	  		  			<v-text-field
	  		  				filled
	  		  				single-line
	  		  			  label="Fecha final"
	  		  			  type="date"
	  		  			  hide-details
	  		  			  v-model="fecha_final"
	  		  			  dense
	  		  			></v-text-field>
	  		  		</v-col>

	  		  		<v-spacer></v-spacer>

	  		  		<v-col cols="12" md="12" lg="6">
	  		  			<v-text-field
	  		  				filled
	  		  				single-line
	  		  			  label="Búscar"
	  		  			  append-icon="mdi-magnify"
	  		  			  hide-details
	  		  			  v-model="buscar"
	  		  			  dense
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>

					  <v-data-table
					    :headers="headers"
					    :items="nomina"
					    dense
					    class="elevation-0 mt-4"
					  >

					  	<template v-slot:item.estatus="{ item }">
					      <v-chip small v-if="!item.autorizo_jefe && !item.autorizo_rh">SIN REVISION</v-chip>
					      <v-chip small v-else-if="item.autorizo_jefe == 1 || item.autorizo_rh == 1" color="green" dark>ACEPTADO</v-chip>
					      <v-chip small v-else-if="item.autorizo_jefe == 2 || item.autorizo_rh == 2" color="red" dark>NO ACEPTADO</v-chip>
					      <v-chip small v-else color="orange" dark>EN REVISION</v-chip>
					    </template>

					    <template v-slot:item.actions="{ item }">
					      <v-icon
					        small
					        class="mr-2"
					        color="blue"
					        @click="editItem(item)"
					      >
					        mdi-eye
					      </v-icon>
					    </template>
					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					      >
					        Reset
					      </v-btn>
					    </template>
					  </v-data-table>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <v-dialog
      v-model="dialog"
      max-width="1400px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h6">Folio: {{ editedItem.id }}</span>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            dark
            rounded
            small
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="black--text" v-if="editedIndex >= 0">
        	<span><b>Solicitante: </b> {{ editedItem.nombre_completo }}</span>
        	<br/>
        	<br/>

        	<v-row>

        		<v-col cols="12">
        			<v-radio-group
					      v-model="opVisualizacion"
					      row
					    >
					      <v-radio
					        label="Egreso"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="Nómina"
					        :value="2"
					      ></v-radio>
					    </v-radio-group>
        		</v-col>

						<v-col cols="12">
        			<v-btn 
	              class="mr-2" 
	              small dark color="green"
	              @click="exportar()">
	              <v-icon>mdi-microsoft-excel</v-icon>
	            </v-btn>
        		</v-col>

        		<v-col :cols="opVisualizacion == 1 ? '12' : '8' " >

        			<v-data-table
						    :headers="opVisualizacion == 1 ? headersDetalle     : headersNomina"
						    :items="opVisualizacion == 1    ? editedItem.detalle : editedItem.nomina"
						    class="elevation-0"
						    dense
						  >

						  	<template v-slot:item.Monto="{ item }">
						      <span>{{ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.Monto ) }}</span>
						    </template>

						    <template v-slot:item.actions="{ item }">
						      <v-icon
						        small
						        class="mr-2"
						        color="blue"
						        @click="editItem(item)"
						      >
						        mdi-eye
						      </v-icon>
						    </template>
						    <template v-slot:no-data>
						      <v-btn
						        color="primary"
						        @click="initialize"
						      >
						        Reset
						      </v-btn>
						    </template>
						  </v-data-table>
        		</v-col>
        	</v-row>
          <!-- <v-row v-if="editedItem.archivo_adjunto != 'null'">
            <v-col cols="12">
              <v-img :src="url + editedItem.archivo_adjunto"/>
            </v-col>
          </v-row> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <AgregarRentaSucursal 
  		@cancelar="cancelar" 
  		v-if="dialogNuevaNomina.estatus"
  		:dialogNuevaNomina="dialogNuevaNomina"
  	/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import AgregarRentaSucursal         from '@/components/egresos/AgregarRentaSucursal.vue';

  import validarErrores  from '@/mixins/validarErrores'
  import funcionesExcel  from '@/mixins/funcionesExcel'
  import leerImagenes    from '@/mixins/leerImagenes'

  const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

  export default {

  	components:{
      Alerta,
      carga,
      AgregarRentaSucursal
    },

    mixins: [ validarErrores, funcionesExcel, leerImagenes ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'ID'          , value: 'id', sortable: false },
        { text: 'Usuario'     , value: 'nombre_completo' },
        { text: 'Ciclo'       , value: 'ciclo' },
        { text: 'Motivo'      , value: 'comentarios' },
        { text: 'Estatus'     , value: 'descripcion' },
        { text: 'Actions'     , value: 'actions', sortable: false },
      ],

      headersDetalle: [
        { text: 'ID'           , value: 'id', sortable: false },
        { text: 'Elemento'     , value: 'elemento_mantenimiento' },
        { text: 'Concepto'     , value: 'concepto'       },
        { text: 'Plantel'      , value: 'plantel'        },
        { text: 'Cantidad'     , value: 'cantidad'       },
        { text: 'Costo Unit.'  , value: 'costo_unitario' },
        { text: 'Total'        , value: 'costo_total'    },
        { text: 'Cotización'   , value: 'cotizacion_sn'  },
        { text: 'Actions'      , value: 'actions', sortable: false },
      ],

      headersNomina: [
        { text: 'Nómina'     , value: 'Nómina', sortable: false },
        { text: 'Empleado'   , value: 'Empleado' },
        { text: 'Cuenta'     , value: 'Cuenta'   },
        { text: 'Monto'      , value: 'Monto'    },
      ],

      nomina: [],
      opVisualizacion: 1,

      editedIndex: -1,
      editedItem: {
        id: '',
        nombre_completo: '',
        detalles: [],
      },
      defaultItem: {
        idsolicitud_nomina: '',
        id_usuario: 0,
        idareas_ticket: 0,
      },

      file: '',
      dragging: false,
      vistaPrevia: null,
      autorizar:null,
      nota_rechazo:'',
      url:'',

      fecha_inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fecha_final: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			buscar:'',

			dialogNuevaNomina:{
				estatus: false
			}

    }),

    computed: {
    	...mapGetters('login', ['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.url = axios.defaults.baseURL + 'justificantes/'

      this.initialize()
    },

    methods: {
      initialize () {
        this.nomina = []
        this.cargar = true
        const payload = {
        	fecha_inicio: this.fecha_inicio,
        	fecha_final : this.fecha_final
        }
        return this.$http.post('consultar.renta.sucursales', payload).then(response=>{
        	this.nomina = response.data
        	this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.nomina.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
      	this.autorizar = null
      	this.nota_rechazo = ''
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      exportar( ){
      	const data = this.opVisualizacion == 1 ? this.editedItem.detalles : this.editedItem.nomina

      	this.exportExcel( data, 'excel_nomina')
      },

      cancelar(){

      },
    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>