var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4 ml-2 pr-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{staticClass:"text-subtitle-1"},[_vm._v(" Renta de sucursales "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","small":"","rounded":""},on:{"click":function($event){return _vm.initialize()}}},[_vm._v("Consultar")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary","small":"","rounded":""},on:{"click":function($event){_vm.dialogNuevaNomina.estatus = true}}},[_vm._v("Agregar")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"2","xl":"2"}},[_c('v-text-field',{attrs:{"filled":"","single-line":"","label":"Fecha inicio","type":"date","hide-details":"","dense":""},model:{value:(_vm.fecha_inicio),callback:function ($$v) {_vm.fecha_inicio=$$v},expression:"fecha_inicio"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"2","xl":"2"}},[_c('v-text-field',{attrs:{"filled":"","single-line":"","label":"Fecha final","type":"date","hide-details":"","dense":""},model:{value:(_vm.fecha_final),callback:function ($$v) {_vm.fecha_final=$$v},expression:"fecha_final"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"12","lg":"6"}},[_c('v-text-field',{attrs:{"filled":"","single-line":"","label":"Búscar","append-icon":"mdi-magnify","hide-details":"","dense":""},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=$$v},expression:"buscar"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0 mt-4",attrs:{"headers":_vm.headers,"items":_vm.nomina,"dense":""},scopedSlots:_vm._u([{key:"item.estatus",fn:function(ref){
var item = ref.item;
return [(!item.autorizo_jefe && !item.autorizo_rh)?_c('v-chip',{attrs:{"small":""}},[_vm._v("SIN REVISION")]):(item.autorizo_jefe == 1 || item.autorizo_rh == 1)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v("ACEPTADO")]):(item.autorizo_jefe == 2 || item.autorizo_rh == 2)?_c('v-chip',{attrs:{"small":"","color":"red","dark":""}},[_vm._v("NO ACEPTADO")]):_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("EN REVISION")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-eye ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}])})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1400px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h6"},[_vm._v("Folio: "+_vm._s(_vm.editedItem.id))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":"","rounded":"","small":"","icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.editedIndex >= 0)?_c('v-card-text',{staticClass:"black--text"},[_c('span',[_c('b',[_vm._v("Solicitante: ")]),_vm._v(" "+_vm._s(_vm.editedItem.nombre_completo))]),_c('br'),_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.opVisualizacion),callback:function ($$v) {_vm.opVisualizacion=$$v},expression:"opVisualizacion"}},[_c('v-radio',{attrs:{"label":"Egreso","value":1}}),_c('v-radio',{attrs:{"label":"Nómina","value":2}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","dark":"","color":"green"},on:{"click":function($event){return _vm.exportar()}}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-col',{attrs:{"cols":_vm.opVisualizacion == 1 ? '12' : '8'}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.opVisualizacion == 1 ? _vm.headersDetalle     : _vm.headersNomina,"items":_vm.opVisualizacion == 1    ? _vm.editedItem.detalle : _vm.editedItem.nomina,"dense":""},scopedSlots:_vm._u([{key:"item.Monto",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( item.Monto )))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"blue"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-eye ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reset ")])]},proxy:true}],null,false,2434799849)})],1)],1)],1):_vm._e()],1)],1),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e(),(_vm.dialogNuevaNomina.estatus)?_c('AgregarRentaSucursal',{attrs:{"dialogNuevaNomina":_vm.dialogNuevaNomina},on:{"cancelar":_vm.cancelar}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }